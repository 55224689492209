
import GeneralData from '../components/GeneralData.vue'
import Items from "@/modules/purchaseInvoices/components/Items.vue";
import usePurchaseInvoice from "@/modules/purchaseInvoices/composables/usePurchaseInvoice";
import toastr from "toastr";
import {onMounted, Ref, ref} from "vue";
import router from "@/router";
import {PurchaseInvoiceCatalogs} from "@/modules/purchaseInvoices/interfaces";

export default {
    components: {GeneralData, Items},
    props: ['id'],
    setup(props: { id: string }) {
        const {
            clearForm,
            purchaseInvoiceForm,
            updatePurchaseInvoice,
            getPurchaseInvoice,
            getCatalogs
        } = usePurchaseInvoice()
        const sending = ref(false)
        const loading = ref(true)
        const catalogs: Ref<PurchaseInvoiceCatalogs | null> = ref(null)

        clearForm();

        onMounted(async () => {
            catalogs.value = await getCatalogs()
            purchaseInvoiceForm.value = await getPurchaseInvoice(props.id)
            loading.value = false
        })

        return {
            mode: 'UPDATE',
            sending,
            loading,
            catalogs,
            cancel: () => router.push({name: 'purchase_invoices'}),
            onSubmit: async () => {
                try {
                    sending.value = true
                    const {ok, message} = await updatePurchaseInvoice(purchaseInvoiceForm.value)

                    if (ok) {
                        toastr.success('Su solicitud se ha procesado correctamente.')
                        await router.push({name: 'purchase_invoices'})
                    } else {
                        await toastr.error(message)
                    }
                } finally {
                    sending.value = false
                }
            }
        }
    }
}
